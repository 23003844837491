
import { defineComponent } from 'vue'
import TmAvatar from '@/components/shared/TmAvatar.vue'

export default defineComponent({
  components: {
    TmAvatar,
  },
  props: {
    emailType: {
      type: String,
      default: 'Ticket received',
    },
    footerMessage: {
      type: String,
      default: 'This email was sent to you by Textmagic.<br>34 Thame Road, Great Haseley, OX44 7JF, United Kingdom',
    },
    white: {
      type: Boolean,
    },
  },
  setup(props) {
    const footerMessageValue = props.footerMessage.replace(/\n/g, '<br />')

    return {
      footerMessageValue,
    }
  },
})
